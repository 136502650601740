/**
 * 
 * Pinia Store: shippingrate beallitasainak allapota: 
 * 
 * 
*/

import { API } from 'aws-amplify'
import * as queries from '~/graphql/queries'

interface ShippingRate {
  price: number | null;
  callForPrice: boolean;
}

interface ShippingRateData {
  [index: string]: ShippingRate;
}

export const useShippingRateStore = defineStore('shippingrate', {
  state: () => ({
    shippingrate: {} as ShippingRateData,
  }),
  getters: {
    getShippingRate(): ShippingRateData {
      return this.shippingrate;
    },
  },
  actions: {
    async fetchShippingRate() {
      try {
        const shippingratesdata = await API.graphql({
          query: queries.listShippingPrices,
          authMode: 'AWS_IAM',
        });
        const shippingpricesfromdb = shippingratesdata.data.listShippingPrices.items;

        const shippingprices: ShippingRateData = {};

        for (const sp of shippingpricesfromdb) {
          // indexek:
          const shippingTypeAsKey: string = sp.shippingType;
          const currencyAsKey: string = sp.currency;
          // ha nincs ilyen index még, akkor létrehozzuk "üresen"
          if (!shippingprices[shippingTypeAsKey]) {
            shippingprices[shippingTypeAsKey] = {
              price: null,
              callForPrice: false,
            };
          }
          // 
          shippingprices[shippingTypeAsKey][currencyAsKey] = {
            price: sp.price,
            callForPrice: sp.callForPrice,
          };
        }

        this.shippingrate = shippingprices;
      } catch (error) {
        console.log(error);
      }
    },
  },
});