export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      type
      deleted
      ean
      name
      image
      mainImage
      author
      binding
      publicationDate
      # price
      calculatedPrices
      currency
      salePercent
      weight
      weightMetric
      sizeDimensionX
      sizeDimensionY
      sizeDimensionZ
      orderable
      orderableInfo
      orderableFlag
      productTemplate
      inventories {
        items {
          productID
          warehouseID
          inventoryAmount
          warehouse {
            id
            name
            type
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`
