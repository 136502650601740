/**
 * 
 * Pinia Store: kosar tartalom allapot
 * 
 * Jelenleg csak a getCartProductsInStockUnique() azaz a "cartproductsinstockunique" tömböt használja,
 * mivel nem különböztetjük meg a kosárba helyezett termékeket a termék elérhetősége alapján.
 * 
 * 
*/

import { API } from 'aws-amplify';
import * as queriesCustomCart from '~/graphql/queries-custom-cart';
// import * as graphqlMutations from '~/graphql/mutations';
import { storeToRefs } from 'pinia';
import { useShippingRateStore } from '@/store/shippingrate';

export const useWebshopCartStore = defineStore('webshopcart', {
  state: () => ({
    // termekek csoportositva elerhetes szerint
    cartproductsinstockunique: [], // "raktáron levő" termékek, egyedi
    cartproductsorderable: [], // "csak rendelhető" termékek
    cartproductsnotorderable: [], // nem rendelhető termékek (ez nem adható hozzá a kosárhoz)
    cartproductspreorder: [], // TODO: [BESTSTOREF-85] előrendelhető termékállapot bevezetése a kosárba
    cartlastaddedproduct: null, // a legutoljára hozzáadott termék
     // termékek száma a kosárban (nem azt mutatja h hány féle termék!)
    numberofcartproducts: 0,
    // TODO: kiszervezni innen (lasd "masik Pinia store hasznalata" egy letezo storeban):
    // kosár modal:
    cartmodal: false, //  megnyitva / bezárva
    cartmodalloading: false, // kosár modal-on belül: loading vagy sem
    // kosar gomb loading allapot:
    cartbuttonloading: false, // a kosár darabszám gobok
    pricesumofproducts: 0, // kosár termékeinek összértéke
    // pricesumofproductsbycurrency: {}, // kosár termékeinek összértéke currency szerint
    // pricesumofproducts.EUR // kell mindegyik pénznemben
    pricesumofshipping: 0, // szállítási mód ára
    pricesumofpayment: 0, // fizetési mód ára
    pricefullsumofcart: 0, // kosár összértéke
    selectedshipping: null, // kosárnál kiválasztott szállítási mód
    selectedpayment: null, // kosárnál kiválasztott fizetési mód
  }),
  getters: {
    getCartProductsInStockUnique(): Array<object> {
      return this.cartproductsinstockunique;
    },
    getCartProductsOrderable(): Array<object> {
      return this.cartproductsorderable;
    },
    getCartProductsNotOrderable(): Array<object> {
      return this.cartproductsnotorderable;
    },
    getCartProductsPreorder(): Array<object> {
      return this.cartproductspreorder;
    },
    getCartLastAddedProduct(): object | null {
      return this.cartlastaddedproduct;
    },
    getNumberOfCartProducts(): number {
      return this.numberofcartproducts;
    },
    getCartModal(): boolean {
      return this.cartmodal;
    },
    getCartModalLoading(): boolean {
      return this.cartmodalloading;
    },
    getCartButtonLoading(): boolean {
      return this.cartbuttonloading;
    },
    getPriceSumOfProducts(): number {
      return this.pricesumofproducts;
    },
    getPriceSumOfShipping(): number {
      return this.pricesumofshipping;
    },
    getPriceSumOfPayment(): number {
      return this.pricesumofpayment;
    },
    getPriceFullSumOfCart(): number {
      return this.pricefullsumofcart;
    },
    getSelectedPayment(): any {
      return this.selectedpayment;
    },
    getSelectedShipping(): any {
      return this.selectedshipping;
    },
  },
  actions: {
    // darabszamok es termekek arainak kalkulacioja
    // hasznalata: state.totalAmount = totals(payload).pricesumofpayment
    totals(
      payloadArrInStock: Array<object>, 
      payloadArrOrderable: Array<object>, 
      payloadArrPreorder: Array<object>
    ) {
      // az osszes azonnal elerheto termek ara egyutt
      const totalAmountInStock = payloadArrInStock.map((cartArr) => {
        return (
// TODO: cartArr.productdata.pricesByCurrency.HUF.priceCalculatedOnSale
          // ez a price a kosárban levő termék price-a NEM a Product táblából érkező price
          cartArr.productdata.price * cartArr.qty
        )
      }).reduce((a, b) => a + b, 0);

      // az osszes rendelheto ara egyutt
      const totalAmountOrderable = payloadArrOrderable.map((cartArr) => {
// TODO: calculatedPrices
          return cartArr.productdata.price * cartArr.qty
        }).reduce((a, b) => a + b, 0);

      // az osszes elorendelheto termek ara egyutt
      const totalAmountPreorder = payloadArrPreorder.map((cartArr) => {
// TODO: calculatedPrices
          return cartArr.productdata.price * cartArr.qty
        }).reduce((a, b) => a + b, 0);

       const totalAmount = totalAmountInStock + totalAmountOrderable + totalAmountPreorder
        // a termekek szama a kosarban
        // const totalQantity = payloadArr
        //   .map((cartArr) => {
        //     return cartArr.qty
        //   })
        //   .reduce((a, b) => a + b, 0)
      return { pricesumofproducts: Number.parseFloat(totalAmount) }
    },
    // Termék kosárhoz adása és a modal ablak kezelése (egyben):
    //  1. default értékek
    //  2. kosáron belüli loading
    //  3. cart modal nyitása
    //  4. egy termék lekérdezése a productid szerint
    //  5. a termék adatainak feldolgozása és map-elése a kosár store-hoz
    //  6. infó üzenetek..
    async addProductToCartByProductId(productid: string) {
      // TODO: ha még nincs kosár ID (süti/webstore), akkor generálunk egyet (UUID)
      // TODO: és ezt az UUID-t mentjük el a DB-be
      this.setCartLastAddedProduct({});
      // loading és modal ablak nyitása
      this.setCartModalLoading(true);
      this.openCartModal();
      // lekrédezzük a terméket:
      try {
        // TODO:    lambda resolver-el megoldani (cart mutations)
        // Itt lekérdezzük a Product aktuális adatait
        const dataholder = await API.graphql({
          query: queriesCustomCart.getProduct,
          variables: {
            id: productid,
          },
          // authMode: 'AMAZON_COGNITO_USER_POOLS',
          authMode: 'AWS_IAM',
        })
        // TODO: ezt és az ezekhez hasonló eseteket felül kell vizsgálni:
        const productdata = dataholder.data.getProduct;
        // TODO:    hibakezelés beépítése (ha az adott termék nem érhető el)
        if (!productdata) {
          console.log('A termék már nem érhető el!');
          return;
        }
        // törölt termék
        if (productdata?.deleted === true) {
          console.log('A termék már nem érhető el!');
          return;
        }
        // A lambda resolverből érkező AWSJSON objektumot parse-olni kell, mert valamiért sima string-ként érkezik
        if(productdata && productdata.calculatedPrices) {
          productdata.calculatedPrices = JSON.parse(productdata.calculatedPrices);
        }
        // FONTOS!
        //  a termék árat létrehozzuk / felülírjuk a webshop default currency szerinti végleges árral
        productdata.price = productdata.calculatedPrices.pricesByCurrency.HUF.priceCalculatedOnSale;

        // const globaldiscountset = dataholder.data.getProduct.calculatedPrices.webshopGlobalSalePercent;
        // await globalDiscountStore.setGlobalDiscount(globaldiscountset);
        // product.value = dataholder.data.getProduct;

        this.addCartProduct(productdata)
        this.setCartLastAddedProduct(productdata);

        // refresh sum of cart:
        this.setPriceFullSumOfCart();

        // TODO: getInventory query!
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
        // $bugsnag.notify(new Error(error))
      }
      this.setCartModalLoading(false);
    },

    addCartProduct(productdata: object) {
      this.openCartModal();
      // Google Analytics kosárhoz adás event:
      if(process.client && process.env.NODE_ENV === 'production') {
        // console.log('gtag: add_to_cart');
        const { gtag } = useGtag();
        gtag('event', 'add_to_cart', {
          // app_name: 'Bestsellers.hu',
          // screen_name: 'Home'
          event_category: 'ecommerce',
          event_label: 'add_to_cart',
          currency: 'HUF',
          value: productdata.price,
          items: [
            {
              item_id: productdata.ean,
              item_name: productdata.name,
              // item_category: productdata.productTemplate,
              // item_category2: ,
              // item_category3: ,
              price: productdata.price,
              quantity: 1,
            },
          ],
        });
      }
      // console.log('addCartProduct with productdata:', productdata);
      // TODO: [BESTSTOREF-84] DB warehouse item darabszám lefoglalás
      this.numberofcartproducts++
      // ha még nem tartalmazza EZT a terméket, hozzáadjuk
      let existingCartItemIndex = ''
      // console.log('this.cartproductsinstockunique', this.cartproductsinstockunique);
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.cartproductsinstockunique)) {
        if (this.cartproductsinstockunique[key].productid === productdata.id) {
          // ha a kosár már tartalmazza ezt a termék ID-t (terméket)
          existingCartItemIndex = key;
          break;
        }
      }
      // ha nincs még ilyen termék, létrehozzuk es beállítjuk h 1 darabról van szó
      if (existingCartItemIndex === '') {
        this.cartproductsinstockunique.push({ productdata, qty: 1, productid: productdata.id });
      } else {
        // ha már van ilyen termék, akkor növeljük a darabszámot:
        this.cartproductsinstockunique[existingCartItemIndex].qty += 1;
      }
      // termek osszertek frissitese
      this.pricesumofproducts = this.totals(
        this.cartproductsinstockunique,
        this.cartproductsorderable,
        this.cartproductspreorder
      ).pricesumofproducts

      // cartItemAdd Lambda resolver mutation:
      // try {
      //   const cartId = uuid.v4()
      //   const loggedIn = await isUserSignedIn()
      //   // { allow: owner, identityClaim: "username", provider: userPools }
      //   // ha még nincs bejelentkezve, akkor az alábbival megy:
      //   const cartItemAddResult = await API.graphql({
      //     query: graphqlMutations.cartItemAdd,
      //     variables: { cartId, productId: productdata.id },
      //     // authMode: 'AWS_IAM',
      //     // authMode: 'AMAZON_COGNITO_USER_POOLS',
      //     authMode: loggedIn === false ? 'AWS_IAM' : 'AMAZON_COGNITO_USER_POOLS',
      //   })
      //   // TODO:
      //   const obj = JSON.parse(JSON.stringify(cartItemAddResult))
      //   console.log('cartItemAddResult', obj)
      // } catch (error) {
      //   // eslint-disable-next-line no-console
      //   console.log('Error', error)
      //   // useBugsnag().notify(new Error(error))
      // }

    },

    // a legutobb hozzaadott termek
    setCartLastAddedProduct(product: any) {
      this.cartlastaddedproduct = product;
    },
    // a kiválasztott szállítási mód
    async setSelectedShipping(shippingType: string) {
      console.log('setSelectedShipping: ', shippingType);
      // shipping rates:
      if (
        shippingType !== 'SHOP' && 
        shippingType !== 'GLS' && 
        shippingType !== 'POSTOUTSIDEHUN' 
        && shippingType !== 'GLSPOINT' ) {
        console.error('Error: the selected shipping type is not defined. Check defined shippingrates too.', shippingType);
      }
// TODO: ezt megvizsgálni:
      // if shipping is changed, selected payment must be reset
      if(this.selectedpayment !== shippingType){
        this.selectedpayment = null;
      }
      
      this.selectedshipping = shippingType;

      // shipping rate a másik store-ból:
      //  (az aktuális currency-nek megfelelően)
      const shippingRateStore = useShippingRateStore();
      const { fetchShippingRate } = shippingRateStore; // all non-reactive stuff
      await fetchShippingRate();
      // const shippingrate = computed(() => shippingRateStore.shippingrate);
      const { shippingrate } = storeToRefs(shippingRateStore) // have all reactive states
      console.log('shippingrate', shippingrate.value[shippingType].HUF.price);
      const pricesumofshipping = shippingrate.value[shippingType].HUF.price;
      // const pricesumofshipping = shippingrate[payloadType].HUF;
      // const pricesumofshipping = store.getters.shippingrate.shippingrate[payloadType]['HUF'];
      // const pricesumofshipping = shippingrate.shippingrate[payloadType]['HUF'];
      this.setPriceSumOfShipping(pricesumofshipping);
      this.setPriceFullSumOfCart();

      // // Google Analytics: egyedi: selected payment_mode event:
      // // csak production környezetben van követőkód (lásd vue-gtag plugin config)
      // if (process.env.NODE_ENV === 'production') {
      //   this._vm.$gtag.event('select_payment_mode', {

      //   })
      // }
    },
    // a kiválasztott fizetési mód
    setSelectedPayment(payload: any) {
      console.log('setSelectedPayment: ', payload);
      this.selectedpayment = payload
    },
    // törli a kiválasztott fizetési módot
    unSelectPayment() {
      this.selectedpayment = null
    },
    // plus one
    async oneMoreProductToCart(productid) {
      if (this.cartbuttonloading) {
        return true;
      }
      this.setCartButtonLoading(true);
      // this.setCurrency();
      try {
        // TODO:    lambda resolver-el megoldani (cart mutations)
        // Itt lekérdezzük a Product aktuális adatait
        const dataholder = await API.graphql({
          query: queriesCustomCart.getProduct,
          variables: {
            id: productid,
          },
          // authMode: 'AMAZON_COGNITO_USER_POOLS',
          authMode: 'AWS_IAM',
        })
        // TODO: ezt és az ezekhez hasonló eseteket felül kell vizsgálni:
        const productdata = dataholder.data.getProduct;
        // TODO:    hibakezelés beépítése (ha az adott termék nem érhető el)
        if (!productdata) {
          console.log('A termék már nem érhető el!');
          return;
        }
        // A lambda resolverből érkező AWSJSON objektumot parse-olni kell, mert valamiért sima string-ként érkezik
        if(productdata && productdata.calculatedPrices) {
          productdata.calculatedPrices = JSON.parse(productdata.calculatedPrices);
        }
        // FONTOS!
        //  a termék árat létrehozzuk / felülírjuk a webshop default currency szerinti végleges árral
        productdata.price = productdata.calculatedPrices.pricesByCurrency.HUF.priceCalculatedOnSale;

        // eslint-disable-next-line no-console
        // console.log('oneMoreProductToCart:', productdata)
        this.addCartProduct(productdata);
        this.setCartLastAddedProduct(productdata);
        // refresh all sums:
        this.setPriceFullSumOfCart();
        // TODO: getInventory query!
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
        // useBugsnag().notify(new Error(error))
      }
      this.setCartButtonLoading(false);
    },
    // minus one
    async removeOneProductFromCart(productid: string) {
      if(this.cartbuttonloading) {
        return true;
      }
      this.setCartButtonLoading(true);
      try {
        // TODO:    lambda resolver-el megoldani (cart mutations)
        // Itt lekérdezzük a Product aktuális adatait
        const dataholder = await API.graphql({
          query: queriesCustomCart.getProduct,
          variables: {
            id: productid,
          },
          // authMode: 'AMAZON_COGNITO_USER_POOLS',
          authMode: 'AWS_IAM',
        })
        // TODO: ezt és az ezekhez hasonló eseteket felül kell vizsgálni:
        const productdata = dataholder.data.getProduct;
        // TODO:    hibakezelés beépítése (ha az adott termék nem érhető el)
        if (!productdata) {
          console.log('A termék már nem érhető el!');
          return;
        }
        // A lambda resolverből érkező AWSJSON objektumot parse-olni kell, mert valamiért sima string-ként érkezik
        if(productdata && productdata.calculatedPrices) {
          productdata.calculatedPrices = JSON.parse(productdata.calculatedPrices);
        }
        // FONTOS!
        //  a termék árat létrehozzuk / felülírjuk a webshop default currency szerinti végleges árral
        productdata.price = productdata.calculatedPrices.pricesByCurrency.HUF.priceCalculatedOnSale;

        // eslint-disable-next-line no-console
        // console.log('removeOneProductFromCart:', productdata)

        // termék árának frissítése currency alapján:
        this.removeCartProduct(productdata);
        // refresh all sums:
        this.setPriceFullSumOfCart();
        // TODO: getInventory query!
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
          // useBugsnag().notify(new Error(error))
        }
        this.setCartButtonLoading(false);
    },
    // beállítja hogy épp load van vagy sem (a termek hozzaadas, torles gombokhoz kell)
    setCartButtonLoading(payload: any) {
      this.cartbuttonloading = payload
    },
    // kosar modal megnyitasa
    openCartModal() {
      this.cartmodal = true
      this.cartmodalloading = true
    },
    // kosar modal bezarasa
    closeCartModal() {
      // console.log('closeCartmodal clicked');
      this.cartmodal = false
    },
    // a kosar teljes kiuritese
    clearCart() {
      // kulonbozo allapotu termekek
      this.cartproductsinstockunique = [] // "raktáron levő" termékek, egyedi
      this.cartproductsorderable = [] // "csak rendelhető" termékek
      this.cartproductsnotorderable = [] // nem rendelhető termékek (ez nem adható hozzá a kosárhoz)
      this.cartproductspreorder = [] // TODO: [BESTSTOREF-85] előrendelhető termékállapot bevezetése a kosárba
      this.cartlastaddedproduct = null // a legutoljára hozzáadott termék
      this.numberofcartproducts = 0 // termékek száma a kosárban (nem azt mutatja h hány féle termék!)
      this.cartmodal = false // kosár modal megnyitva / bezárva
      this.cartmodalloading = false // kosár modal-on belül: loading vagy sem
      this.cartbuttonloading = false // a kosár darabszám gobok
      this.pricesumofproducts = 0 // kosár termékeinek összértéke
      this.pricesumofshipping = 0 // szállítási mód ára
      this.pricesumofpayment = 0 // fizetési mód ára
      this.pricefullsumofcart = 0 // kosár összértéke
      this.selectedshipping = null // kosárnál kiválasztott szállítási mód
      this.selectedpayment = null // kosárnál kiválasztott fizetési mód
    },
    // a modal ablakban levo adatok toltesi kijelzese
    setCartModalLoading(payload: any) {
      this.cartmodalloading = payload
    },
    // szallitasi modok kultsege, kulonbozo penznemekben
    setPriceSumOfShipping(payload: any) {
      // shipping price by rate, by actual currency:
      this.pricesumofshipping = payload
    },
    setPriceFullSumOfCart() {
      // nullazas
      this.pricefullsumofcart = 0
      if (this.pricesumofproducts) {
        this.pricefullsumofcart += this.pricesumofproducts
      }
      if (this.pricesumofshipping) {
        this.pricefullsumofcart += this.pricesumofshipping
      }
    },
    // set to zero: sum of cart
    cleanPriceFullSumOfCart() {
      this.pricefullsumofcart = 0
    },
    // egy termek törlése a kosárból
    removeCartProduct(productdata: object) {
      // Google Analytics kosárból törlés event:
      if(process.client && process.env.NODE_ENV === 'production') {
        console.log('gtag: remove_from_cart');
        const { gtag } = useGtag();
        gtag('event', 'remove_from_cart', {
          // app_name: 'Bestsellers.hu',
          // screen_name: 'Home'
          event_category: 'ecommerce',
          event_label: 'remove_from_cart',
          currency: 'HUF',
          value: productdata.price,
          items: [
            {
              item_id: productdata.ean,
              item_name: productdata.name,
              // item_category: productdata.productTemplate,
              // item_category2: ,
              // item_category3: ,
              price: productdata.price,
              quantity: 1,
            },
          ],
        });
      }
      this.numberofcartproducts--
      for (const [key, value] of Object.entries(
        this.cartproductsinstockunique
      )) {
        if (this.cartproductsinstockunique[key].productid === productdata.id) {
          // ha a kosár tartalmazza ezt a terméket (azonosito alapjan):
          // ha a kosárban levő termék darabszáma > 0, akkor a darabszámot csökkentjük
          if (this.cartproductsinstockunique[key].qty > 0) {
            this.cartproductsinstockunique[key].qty--;
          }
          // ha a kosárban levő termék darabszáma 0, akkor a terméket töröljük a kosárból
          if (this.cartproductsinstockunique[key].qty === 0) {
            this.cartproductsinstockunique.splice(key, 1);
            break;
          }
          break;
        }
      }

      // TODO: [BESTSTOREF-83] ha nincs semmilyen más termék a kosárban, akkor töröljük a teljes kosarat
      if (
        this.cartproductsinstockunique.length === 0 &&
        this.cartproductsorderable.length === 0 &&
        this.cartproductspreorder.length === 0
      ) {
        this.cartproductsinstockunique = [] // "raktáron levő" termékek, egyedi
        this.cartproductsorderable = [] // "csak rendelhető" termékek
        this.cartproductsnotorderable = [] // nem rendelhető termékek (ez nem adható hozzá a kosárhoz)
        this.cartproductspreorder = [] // TODO: [BESTSTOREF-85] előrendelhető termékállapot bevezetése a kosárba
        this.cartlastaddedproduct = null // a legutoljára hozzáadott termék
        this.numberofcartproducts = 0 // termékek száma a kosárban (nem azt mutatja h hány féle termék!)
        this.cartmodal = false // kosár modal megnyitva / bezárva
        this.cartmodalloading = false // kosár modal-on belül: loading vagy sem
        this.cartbuttonloading = false // a kosár darabszám gobok
        // this.pricesumofonetypecartproducts: 0,
        this.pricesumofproducts = 0 // kosár termékeinek összértéke
        this.pricesumofshipping = 0 // szállítási mód ára
        this.pricesumofpayment = 0 // fizetési mód ára
        this.pricefullsumofcart = 0 // kosár összértéke
        this.selectedshipping = null // kosárnál kiválasztott szállítási mód
        this.selectedpayment = null // kosárnál kiválasztott fizetési mód
      }
      // termek osszertek frissitese
      this.pricesumofproducts = this.totals(
        this.cartproductsinstockunique,
        this.cartproductsorderable,
        this.cartproductspreorder
      ).pricesumofproducts
      // TODO: [BESTSTOREF-82] DB warehouse item darabszám felszabadítás
    },
  },
  persist: {
    // a) localStorage-ben tarolva (SSR eseten nem lesz elerheto server oldalon):
    storage: persistedState.localStorage,
    // b) cookie-ban tarolva (SSR eseten rendben lesz,):
    /*
    // A sutiben tarolando ertekek:
TODO: Ez csak akkor lesz jó, ha a termékadatokat query adja vissza, nem pedig a cookie tárolja.
    paths: [
      'cartproductsinstockunique', 
      'cartproductsorderable', 
      'cartproductsnotorderable', 
      'cartproductspreorder', 
      // 'cartlastaddedproduct', // ez túl nagy méretű és amúgy is csak addig kell, amíg a modal ablak nyitva van.
      'numberofcartproducts', 
      'pricesumofproducts', 
      'pricesumofshipping', 
      'pricesumofpayment', 
      'pricefullsumofcart', 
      'selectedshipping', 
      'selectedpayment', 
    ],
    storage: persistedState.cookiesWithOptions({
      path: '/',
      // lejarat 30 nap:
      maxAge: 60 * 60 * 24 * 30,
      domain: process.env.APP_COOKIE_DOMAIN,
      sameSite: 'strict',
      //   sameSite: 'lax',
    }),
    */   
  },
});
